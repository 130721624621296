import styles from './Header.module.css';

/*
    DropDown for header items
*/
const HeaderDropDown = ({title,children}) => {
    return(
        <div className={styles.dropDown}>
            <h2 className={styles.dropDownTitle}>{title ? title : 'عنوان'}</h2>
            {children}
        </div>
    );
}
export default HeaderDropDown