import {
  Button,
  ButtonContainer,
  CreateUser,
  DashContent,
  Module,
  Paginate,
  StatsContainer,
  StatsModule,
  UserDetail,
  UsersTable,
} from "../../components";
import styles from "./ResellerHomePage.module.css";
import { useEffect, useRef, useState } from "react";
import { getMyStats, getUsers } from "../../services/APIs";
import { storageUserInfo } from "../../services/auth";
import { useNavigate } from "react-router-dom";

/*
    Reseller Side Home page
*/
const ResellerHomePage = () => {
  // Refs
  const CreateUserRef = useRef();
  const UserDetailRef = useRef();
  const navigate = useNavigate();

  // States
  const [users, setUsers] = useState([]);
  const [panels, setPanels] = useState([]);
  const [createUserPopup, setCreateUserPopup] = useState(false);
  const [userDetailPopup, setUserDetailPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [currentItems, setCurrentItems] = useState([]);
  const [stats, setStats] = useState([0, 0]);
  const userInfo = storageUserInfo();

  // Fetch Sample Data From Sample JSON on load
  useEffect(() => {
    if (userInfo?.type === "super_admin") {
      navigate("/dashboard/home");
    }
    getMyStats(
      (res) => {
        let allUsers = 0;
        let activeUsers = res.users.by_status.filter(
          (item) => item.value === "active"
        )[0].count;
        for (let i = 0; i < res.users.by_status.length; i++) {
          allUsers = allUsers + res.users.by_status[i].count;
        }
        setStats([allUsers, activeUsers]);
      },
      (error) => {}
    );
    getAllUsers();
    // setUsers(SampleData);
    // setPanels(SamplePanels);
  }, []);

  // Plans Options
  const panelOptions = [];
  panels.map((panel) =>
    panelOptions.push({ value: panel.id, label: panel.name })
  );

  // Open Create User Popup
  const openCreateUser = () => {
    setCreateUserPopup(true);
  };

  // Open User detail Popup
  const openUserDetail = (user) => {
    setSelectedUser(user);
    setUserDetailPopup(true);
  };

  const getAllUsers = () => {
    getUsers(
      "",
      (res) => setCurrentItems(res.users),
      (error) => {}
    );
  };

  return (
    <DashContent>
      {/* POPUPS*/}
      {createUserPopup && (
        <CreateUser
          setPopupStatus={setCreateUserPopup}
          ref={CreateUserRef}
          panels={panelOptions}
          getAllUsers={getAllUsers}
        />
      )}
      {userDetailPopup && (
        <UserDetail
          ref={UserDetailRef}
          setPopupStatus={setUserDetailPopup}
          user={selectedUser}
          getAllUsers={getAllUsers}
        />
      )}

      <div className={styles.stats}>
        <StatsContainer customClass={styles.statsContainer}>
          <StatsModule
            color={"green"}
            width={25}
            height={95}
            title={"کاربران فعال"}
            passed={stats[1]}
            total={stats[0]}
            customClass={styles.statsCard}
            icon={<i className="fa-light fa-users"></i>}
          />
          <StatsModule
            color={"blue"}
            width={25}
            height={95}
            title={"حجم استفاده شده"}
            passed={
              userInfo
                ? parseInt(userInfo?.used_traffic / (1024 * 1024 * 1024))
                : 0
            }
            total={
              userInfo
                ? parseInt(userInfo?.max_user_data_limit / (1024 * 1024 * 1024))
                : 0
            }
            customClass={styles.statsCard}
            icon={<i className="fa-light fa-chart-pie-simple"></i>}
            unit={"GB"}
          />
          <StatsModule
            color={"yellow"}
            width={25}
            height={95}
            title={"حجم باقی مانده"}
            passed={
              userInfo
                ? parseInt(
                    (userInfo?.max_user_data_limit - userInfo?.used_traffic) /
                      (1024 * 1024 * 1024)
                  )
                : 0
            }
            customClass={styles.statsCard}
            unit={"GB"}
            icon={<i className="fa-light fa-database"></i>}
          />
        </StatsContainer>
      </div>
      <Module
        title={"کاربران"}
        icon={<i className="fa-light fa-users"></i>}
        height={70}
        header={
          <ButtonContainer>
            <Button
              text={"کاربر جدید"}
              color={"purple"}
              onClickFunction={openCreateUser}
            />
          </ButtonContainer>
        }
        footer={
          <Paginate
            items={users}
            setCurrentItems={setCurrentItems}
            itemsPerPage={2}
          />
        }
      >
        <UsersTable users={currentItems} openUserDetail={openUserDetail} />
      </Module>
    </DashContent>
  );
};
export default ResellerHomePage;
