import { Link } from "react-router-dom";
import { Badge, Table, TableHead } from "../index";
import styles from "../../pages/ResellerHome/ResellerHomePage.module.css";
import { Tooltip } from "react-tooltip";

/*
    Users Table in Reseller side Home page
*/
const UsersTable = ({ users, openUserDetail }) => {
  return (
    <Table>
      <TableHead>
        <th>نام کاربری</th>
        <th>وضعیت</th>
        <th>انقضا</th>
        <th>حجم استفاده شده</th>
        <th>عملیات</th>
      </TableHead>
      <tbody>
        {users
          ? users.map((user) => {
              return (
                <tr key={user.id} className={"english"}>
                  <th>
                    <Link
                      onClick={() => {
                        openUserDetail(user);
                      }}
                    >
                      {user.username}
                    </Link>
                  </th>
                  <th>
                    {user.status === "active" ? (
                      <Badge
                        text={"active"}
                        color={"green"}
                        icon={<i className="fa-regular fa-check"></i>}
                      />
                    ) : null}
                    {user.status === "disable" ? (
                      <Badge
                        text={"disable"}
                        color={"red"}
                        icon={<i className="fa-solid fa-ban"></i>}
                      />
                    ) : null}
                    {user.status === "on_hold" ? (
                      <Badge
                        text={"on hold"}
                        color={"purple"}
                        icon={<i className="fa-regular fa-clock"></i>}
                      />
                    ) : null}
                  </th>
                  <th> در {parseInt(user.expire / 86400)} روز آینده</th>
                  <th className={"english"}>{`${parseInt(
                    user.used_traffic / (1024 * 1024 * 1024)
                  )}/${parseInt(
                    user.data_limit / (1024 * 1024 * 1024)
                  )} GB`}</th>
                  <th>
                    <div className={styles.btnContainer}>
                      <Tooltip anchorSelect={".qr"} place={"top"}>
                        QR Code
                      </Tooltip>
                      <Link className={"qr"}>
                        <i className="fa-regular fa-qrcode"></i>
                      </Link>

                      <Tooltip anchorSelect={".config"} place={"top"}>
                        Copy Config
                      </Tooltip>
                      <div
                        className={"config"}
                        onClick={() =>
                          window.navigator.clipboard.writeText(user.links[0])
                        }
                      >
                        <i className="fa-regular fa-clipboard"></i>
                      </div>

                      <Tooltip anchorSelect={".sub"} place={"top"}>
                        Copy Subscription Link
                      </Tooltip>
                      <div
                        className={"sub"}
                        onClick={() =>
                          window.navigator.clipboard.writeText(
                            user.subscription_url
                          )
                        }
                      >
                        <i className="fa-regular fa-link"></i>
                      </div>
                    </div>
                  </th>
                </tr>
              );
            })
          : null}
      </tbody>
    </Table>
  );
};
export default UsersTable;
