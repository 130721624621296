import * as yup from 'yup';

export const PanelSchema = yup.object().shape({
    name: yup.string().required('نام کاربری الزامی می باشد.'),
    username: yup.string().required('نام کاربری الزامی می باشد.'),
    password: yup.string().required('کلمه عبور الزامی می باشد.'),
    ip: yup.string().required('آیپی الزامی می باشد.'),

})

