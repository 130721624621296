import styles from './Table.module.css';

/*
    Custom Designed Table
*/
const Table = ({children}) => {
    return (
        <table className={styles.table}>
            {children}
        </table>
    );
}
export default Table;