import * as yup from 'yup';

export const CreatePlanSchema = yup.object().shape({
    name: yup.string().required('نام کاربری الزامی می باشد.'),
    traffic: yup.number().required('ترافیک الزامی می باشد.'),
    capacity: yup.number().required('ظرفیت الزامی می باشد.'),
    inbounds: yup.array().required('آیپی الزامی می باشد.').min(1),

})

export const PlanDetailSchema = yup.object().shape({
    name: yup.string().required('نام کاربری الزامی می باشد.'),
    total_traffic: yup.number().required('ترافیک الزامی می باشد.'),
    total_capacity: yup.number().required('ظرفیت الزامی می باشد.'),

})

export const ChangeLocationSchema = yup.object().shape({
    origin: yup.object().required('سرور مبدا الزامی می باشد.'),
    dest: yup.object().required(' سرور مقصد الزامی می باشد.'),


})