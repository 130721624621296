import {
  AddAgent,
  AgentDetail,
  AgentsTable,
  Button,
  ButtonContainer,
  DashContent,
  Module,
  Paginate,
} from "../../components";
import SampleData from "../../Sample Data/AgentSample.json";
import { useEffect, useRef, useState } from "react";
import { getSubOrdinatesList } from "../../services/APIs";
import { toast } from "react-toastify";

/*
    Agents Management Page 
*/
const AgentsPage = () => {
  // Refs
  const AddAgentRef = useRef(null);
  const AgentDetailRef = useRef(null);

  // States
  const [agents, setAgents] = useState([]);
  const [createPopupStatus, setCreatePopupStatus] = useState(false);
  const [agentDetailPopup, setAgentDetailPopup] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);

  // Fetch Sample Data From Sample JSON on load
  useEffect(() => {
    getSubordinatesList();
  }, []);

  const getSubordinatesList = () => {
    getSubOrdinatesList(
      "",
      (res) => setAgents(res.items),
      (error) => toast.error("مشکلی پیش آمده است.")
    );
  };

  // Open Add agent Popup
  const openAddAgentPanel = () => {
    setCreatePopupStatus(true);
  };

  // Open Agent Detail Popup
  const openAgentDetail = (agent) => {
    setAgentDetailPopup(true);
    setSelectedAgent(agent);
  };

  return (
    <DashContent>
      {createPopupStatus && (
        <AddAgent
          setPopupStatus={setCreatePopupStatus}
          getSubordinates={getSubordinatesList}
          ref={AddAgentRef}
        />
      )}
      {agentDetailPopup && (
        <AgentDetail
          ref={AgentDetailRef}
          setPopupStatus={setAgentDetailPopup}
          getSubordinates={getSubordinatesList}
          agent={selectedAgent}
        />
      )}
      <Module
        title={"نماینده ها"}
        icon={<i className="fa-light fa-users"></i>}
        height={100}
        header={
          <ButtonContainer>
            <Button
              text={"نماینده جدید"}
              color={"purple"}
              onClickFunction={openAddAgentPanel}
            />
          </ButtonContainer>
        }
        footer={
          <Paginate items={agents ?? []} setCurrentItems={setCurrentItems} />
        }
      >
        <AgentsTable agents={currentItems} openAgentDetail={openAgentDetail} />
      </Module>
    </DashContent>
  );
};
export default AgentsPage;
