import { Link } from "react-router-dom";
import { Badge, Table, TableHead } from "../index";
import styles from "../../pages/Agents/AgentsPage.module.css";

/*
    Agents Table
*/
const AgentsTable = ({ agents, openAgentDetail }) => {
  return (
    <Table>
      <TableHead>
        <th>نام</th>
        <th>وضعیت</th>
        <th>کاربر فعال</th>
        <th>دیتای استفاده شده</th>
        {/* <th>پنل ها</th> */}
      </TableHead>
      <tbody>
        {agents &&
          agents.map((agent) => {
            return (
              <tr key={agent.id}>
                <th>
                  <Link
                    onClick={() => {
                      openAgentDetail(agent);
                    }}
                  >
                    {agent.full_name}
                  </Link>
                </th>
                <th>
                  {!agent.is_active ? (
                    <Badge
                      text={"disable"}
                      color={"red"}
                      icon={<i className="fa-solid fa-ban"></i>}
                      english
                    />
                  ) : null}
                  {agent.is_active ? (
                    <Badge
                      text={"active"}
                      color={"green"}
                      icon={<i className="fa-regular fa-check"></i>}
                      english
                    />
                  ) : null}
                </th>
                <th className={"english"}>
                  {agent.created_users_count} / {agent.max_created_users_count}
                </th>
                <th className={"english"}>
                  {parseInt(agent.used_traffic / (1024 * 1024 * 1024))}GB /{" "}
                  {parseInt(agent.max_used_traffic / (1024 * 1024 * 1024))}GB
                </th>
                {/* <th>
                  <div className={styles.panelsContainer}>
                    {agent?.panels?.map((panel) => {
                      return (
                        <Badge text={panel} color={"blue"} key={panel.id} />
                      );
                    })}
                  </div>
                </th> */}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
export default AgentsTable;
