import styles from "./Form.module.css";
import { useEffect, useRef, useState } from "react";
import { Field, getIn, useFormikContext } from "formik";
// import persian from "react-date-object/calendars/english";
// import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker from "react-multi-date-picker";

/*
    Input Field with Formik <Field/>
    &
    Date Picking with react-multi-date-picker module and custom styles
*/
const Input = ({ placeHolder, type, value, readOnly, name, isDate }) => {
  // Refs
  const PlaceHolderRef = useRef();
  const ErrorRef = useRef();

  // States
  const [inputValue, setInputValue] = useState();

  // Formik states and helpers via React Context.
  const formikProps = useFormikContext();

  // Active PlaceHolder effect if value has value on load
  useEffect(() => {
    if (value) {
      activeInput();
    }
  }, []);

  // change inputValue state and active PlaceHolder Effect
  const updateStatus = (value) => {
    setInputValue(value);
    activeInput();
  };

  // Active PlaceHolder Focus effect
  const activeInput = () => {
    PlaceHolderRef.current.classList.add(styles.active_input);
  };

  // Deactivate PlaceHolder Focus effect if input is empty
  const deactivateInput = () => {
    if (!inputValue) {
      PlaceHolderRef.current.classList.remove(styles.active_input);
    }
  };

  // Change Input styles on Error
  const getStyles = (errors, fieldName) => {
    if (getIn(errors, fieldName)) {
      return {
        border: "2px solid var(--red)",
        boxShadow: "none",
      };
    }
  };

  // Set the value of a date field imperatively.
  const setDateValue = (field, date) => {
    // Date format
    date = date.format();
    setInputValue(date);
    formikProps.setFieldValue(field, date);
  };

  return (
    <div className={styles.container}>
      <label className={styles.placeholder}>
        <p className={styles.placeholder_inner} ref={PlaceHolderRef}>
          {placeHolder}
        </p>
      </label>

      <Field name={name} value={value}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { errors },
          meta,
        }) => (
          <div>
            {isDate ? (
              <DatePicker
                onFocusedDateChange={(date) => {
                  setDateValue(field.name, date);
                }}
                // calendar={english}
                // locale={persian_fa}
                inputClass={styles.input}
                containerClassName={styles.datePickerContainer}
                onOpen={activeInput}
                className="purple"
                style={getStyles(errors, name)}
              />
            ) : (
              <input
                type={type}
                {...field}
                className={styles.input}
                onKeyUp={() => {
                  updateStatus(field.value);
                }}
                onFocus={activeInput}
                onBlurCapture={deactivateInput}
                style={getStyles(errors, name)}
                readOnly={readOnly}
              />
            )}
            {meta.error && (
              <div className={styles.error} ref={ErrorRef}>
                {meta.error}
              </div>
            )}
          </div>
        )}
      </Field>
    </div>
  );
};
export default Input;
