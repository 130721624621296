import styles from './DashContent.module.css';

/*
    Main content container in dashboard pages
*/
const DashContent = ({children, title, customClass}) => {
    return (
        <section className={`${styles.container} ${customClass}`}>
            {title ? <h1>{title}</h1> : null}
            <div className={styles.content}>
                {children}
            </div>
        </section>
    );
}
export default DashContent;