import styles from "./Form.module.css";
import {Form, Formik} from "formik";

/*
 Main Formik form exported as <Form/> in index.jsx
*/
const FormContainer = ({children, initialValues,validationSchema, onSubmit}) => {
    return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    <Form className={styles.form}>
                        {children}
                    </Form>
            </Formik>
    )
}
export default FormContainer;