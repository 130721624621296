import styles from './Form.module.css';
import {Field, useFormikContext} from "formik";
import {useRef} from "react";

/*
 Custom-Designed Checkbox with Formik Validation
*/
const Checkbox = ({text, state, setState, name}) => {

    // Checkbox Ref
    const checkboxRef = useRef(null)

    // Formik states and helpers via React Context.
    const formikProps = useFormikContext();

    // Set the value of a field imperatively.
    const handleChange = (field) => {
        formikProps.setFieldValue(field, checkboxRef.current.checked)
        try{
            setState(!state)
        }catch (e) {
            
        }
    }
    return (

        <label className={styles.checkboxContainer}>
            {text}
            <Field name={name}>
                {({field}) => (
                        <input type="checkbox" defaultChecked={field.value}
                               onChange={() => handleChange(field.name)} ref={checkboxRef}/>
                )}
            </Field>

            <span className={styles.checkmark}></span>
        </label>
    );
}
export default Checkbox;