import styles from './Navbar.module.css';
import {NavLink} from "react-router-dom";
import {Tooltip} from "react-tooltip";

/*
    Side Navbar Items
*/
const NavItem = ({to, text, ToggleNavbar, children, navStatus, customClass}) => {
    return (
        <>
            {/* ToolTips on closed Sidebar */}
            {navStatus === 'close' ? <Tooltip anchorSelect={`.${to}`} place={"left"}>{text}</Tooltip> : null}

            <NavLink onClick={ToggleNavbar} className={`${styles.navItem} ${to}`} to={to}
                     style={({isActive}) => ({
                         backgroundColor: isActive ? '#19191F' : 'transparent'
                     })}>
                {children ? children : <i className="fa-regular fa-house"></i>}
                <p>{text ? text : 'عنوان'}</p>
            </NavLink>
        </>
    );
}
export default NavItem;