import { useEffect, useState } from "react";
import {
  DashContent,
  Module,
  StatsContainer,
  StatsModule,
} from "../../components";
import styles from "./AdminHomePage.module.css";
import { getMyStats } from "../../services/APIs";

/*
    Admin Side Home Page
*/
const AdminHomePage = () => {
  const [myStats, setMyStats] = useState();
  useEffect(() => {
    getMyStats(
      (res) => setMyStats(res),
      (error) => {}
    );
  }, []);
  return (
    <DashContent>
      <Module
        hideScroll
        width={100}
        height={60}
        title={"آمار"}
        icon={<i className="fa-regular fa-chart-simple"></i>}
        customClass={styles.statsModule}
      >
        <StatsContainer customClass={styles.statsContainer}>
          <StatsModule
            color={"green"}
            width={25}
            height={95}
            title={"توزیع کننده های فعال"}
            passed={
              myStats?.subordinates?.by_is_active?.filter(
                (item) => item.value
              )[0]?.count ?? 0
            }
            total={
              myStats?.subordinates?.by_is_active?.filter(
                (item) => item.value
              )[0]?.count ??
              0 +
                myStats?.subordinates?.by_is_active?.filter(
                  (item) => !item.value
                )[0]?.count ??
              0
            }
            customClass={styles.statsCard}
          />
          {/* <StatsModule
            color={"blue"}
            width={25}
            height={95}
            title={"کاربران فعال"}
            passed={40}
            total={100}
          /> */}
          <StatsModule
            color={"pink"}
            width={25}
            height={95}
            title={"کاربران فعال توزیع کننده ها"}
            passed={
              myStats?.users.by_status.filter(
                (item) => item.value === "active"
              )[0]?.count ?? 0
            }
            total={
              myStats?.users.by_status[0]?.count +
              myStats?.users.by_status[1]?.count +
              myStats?.users.by_status[2]?.count +
              myStats?.users.by_status[3]?.count +
              myStats?.users.by_status[4]?.count
            }
          />
          {/* <StatsModule
            color={"yellow"}
            width={25}
            height={95}
            title={"پنل های فعال"}
            passed={5}
            total={10}
            unit={"عدد"}
          /> */}
        </StatsContainer>
      </Module>
      {/* <Module
        hideScroll
        width={32}
        height={35}
        customClass={styles.homeModule}
      />
      <Module
        hideScroll
        width={33}
        height={35}
        customClass={styles.homeModule}
      />
      <Module
        hideScroll
        width={33}
        height={35}
        customClass={styles.homeModule}
      /> */}
    </DashContent>
  );
};
export default AdminHomePage;
