import { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Input50,
  Popup,
  Switch,
} from "../index";
import styles from "./Agent.module.css";
import { AgentSchema } from "../../validation/AgentSchema";
import closePopup from "../Popup/ClosePopup";
import { toast } from "react-toastify";
import { getInbounds, putOneSubOrdinate } from "../../services/APIs";

/*
    Change Agent Detail Popup
*/
const AgentDetail = forwardRef(
  ({ setPopupStatus, getSubordinates, agent }, ref) => {
    const [inbounds, setInbounds] = useState([]);
    // Initial field values of the form, Formik will make these values available to render methods component as values.
    const formInitialValues = {
      name: agent.full_name,
      username: agent.username,
      password: agent.password,
      total_capacity: parseInt(agent.max_used_traffic / (1024 * 1024 * 1024)),
      // max_days: agent.max_days,
      // cronjob: agent.cronjob,
      is_active: agent.is_active,
    };

    useEffect(() => {
      getInbounds(
        (res) => {
          let allInbounds = [
            ...res.shadowsocks,
            ...res.trojan,
            ...res.vless,
            ...res.vmess,
          ];
          let locInbounds = [];
          for (let i = 0; allInbounds.length; i++) {
            locInbounds = [
              ...locInbounds,
              {
                value: i,
                label: allInbounds[i].tag,
                type: allInbounds[i].protocol,
              },
            ];
            if (i === allInbounds.length - 1) {
              setInbounds(locInbounds);
            }
          }
        },
        (error) => {}
      );
    }, []);

    // Edit Agent details function
    const editAgent = (values) => {
      let locInbounds = [];
      for (let i = 0; i < inbounds.length; i++) {
        locInbounds = [...locInbounds, inbounds[i].label];
      }
      let payload = {
        notes: "",
        first_name: values.name,
        last_name: "",
        address: "",
        referrer: "",
        phone_number: "",
        telegram_id: 0,
        discord_webhook: "",
        max_subordinates_count: 100,
        max_created_users_count: 100,
        max_subordinates_traffic: 0,
        max_used_traffic: values.total_capacity,
        min_user_data_limit: 0,
        max_user_data_limit: 0,
        max_user_duration: "P3D",
        user_username_prefix: "",
        user_username_suffix: "",
        expires_at: "2024-08-20T10:36:39.003Z",
        inbounds: locInbounds,
        password: values.password,
        marzban_username: "admin",
        marzban_password: "admin",
        panel_address: "https://example.com/",
        type: "admin",
        is_active: values.is_active,
      };
      putOneSubOrdinate(
        values.username,
        payload,
        (res) => {
          getSubordinates();
          closePopup(ref, setPopupStatus);
          toast.success("نماینده با موفقیت بروزرسانی شد.");
        },
        (error) => toast.error("مشکلی پیش آمده است.")
      );
    };

    return (
      <Popup
        setPopupStatus={setPopupStatus}
        ref={ref}
        title={agent.name}
        icon={<i className="fa-solid fa-user"></i>}
      >
        <Form
          initialValues={formInitialValues}
          validationSchema={AgentSchema}
          onSubmit={(values) => editAgent(values)}
        >
          <Input
            placeHolder={"نام"}
            type={"text"}
            value={agent.full_name}
            name={"name"}
          />
          <Input
            placeHolder={"نام کاربری"}
            type={"text"}
            value={agent.username}
            name={"username"}
            readOnly
          />
          <Input
            placeHolder={"کلمه عبور"}
            type={"password"}
            value={agent.password}
            name={"password"}
          />
          <Input
            placeHolder={"ظرفیت"}
            type={"number"}
            value={agent.max_used_traffic}
            name={"total_capacity"}
          />

          {/* <Input50>
          <Checkbox text={"کرون جاب"} name={"cronjob"} />
          <Input
            placeHolder={"حداکثر روز"}
            type={"number"}
            value={agent.max_days}
            name={"max_days"}
          />
        </Input50> */}
          {/* <Input50>
            <Button text={"فعالسازی همه کاربران"} color={"green"} formButton />
            <Button text={"غیرفعالسازی همه کاربران"} color={"red"} formButton />
          </Input50> */}
          {/* <Button text={"پاک کردن همه کاربران"} color={"red"} formButton /> */}
          <Input50 customclass={styles.footer}>
            <Button text={"ثبت تغییرات"} formButton submit />
            <div className={styles.footerContainer}>
              <Switch
                label={"فعال / غیرفعال"}
                checked={agent.is_active}
                name={"is_active"}
              />
              <span onClick={() => console.log("Enter user Panel")}>
                <i
                  className={`${styles.enter} fa-solid fa-right-to-bracket`}
                ></i>
              </span>
            </div>
          </Input50>
        </Form>
      </Popup>
    );
  }
);
export default AgentDetail;
