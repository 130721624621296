import styles from "./StatsModule.module.css";


/*
    Statistical Module
*/
const StatsModule = ({width, height, color,icon,title,passed,total,unit,customClass}) => {
    return (
        <div className={`${styles.container} ${styles.statsCard} ${customClass} `} style={{
            width: `calc(${width}% - 20px)`,
            height: `calc(${height}% - 40px)`,
        }}>
            <div className={`${styles.layer} ${styles.mainLayer}`}>
                <div className={styles.title} style={{
                    color : passed < 0 ? `var(--red)` : `var(--${color})`
                }}>
                    {icon ? icon : <i className="fa-duotone fa-chart-simple"></i>}
                    <h2>{title ? title : 'عنوان'}</h2>
                </div>
                <div className={styles.content}>
                    <div className={styles.stats} >

                        <h3 style={{
                            color : `var(--${color})`
                        }}>{total ? `${total}/` : null}</h3>
                        <h1 style={{
                            color: passed < 0 ? `var(--red)` : `#fff`,
                            direction:'ltr'
                        }}>{passed}</h1>


                    </div>
                    <h2>{unit ? unit : 'نفر'}</h2>
                </div>
            </div>
            <div className={styles.layer}></div>
            <div className={styles.layer}></div>
        </div>
    );
}
export default StatsModule;