import { Link } from "react-router-dom";
import { Badge, Button, Table, TableHead } from "../index";
import styles from "../../pages/Panels/PanelsPage.module.css";

/*
    Panels Table
*/
const PanelsTable = ({ panels, openPanelDetail }) => {
  return (
    <Table>
      <TableHead>
        <th>نام</th>
        <th>وضعیت</th>
        {/* <th>عملیات</th> */}
      </TableHead>
      <tbody>
        {panels &&
          panels.map((panel) => (
            <tr key={panel.id}>
              <th>
                <Link onClick={() => openPanelDetail(panel)}>{panel.name}</Link>
              </th>
              <th>
                {panel.status === "disable" ? (
                  <Badge
                    text={"disable"}
                    color={"red"}
                    icon={<i className="fa-solid fa-ban"></i>}
                    english
                  />
                ) : null}
                {panel.status === "active" ? (
                  <Badge
                    text={"active"}
                    color={"green"}
                    icon={<i className="fa-regular fa-check"></i>}
                    english
                  />
                ) : null}
              </th>
              {/* <th>
                <div className={styles.tableButtonContainer}>
                  <Button text={"Backup"} color={"blue"} />
                  <Button text={"Restore"} color={"blue"} />
                </div>
              </th> */}
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
export default PanelsTable;
