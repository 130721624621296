import styles from './Module.module.css';

/*
    Dashboard Module Components
    width & height in %
    header & footer jsx component
*/
const Module = ({children, title, icon, width, height, header, footer, color, hideScroll, noHeader,noPadding, customClass}) => {
    return (
        <div className={`${styles.container} ${customClass}`} style={{
            width: `calc(${width}% - 10px)`,
            height: height ? `calc(${height}% - 10px)` : 'unset',
            backgroundColor: `var(--${color}-bg)`,
            padding : `${noPadding ? '0' : ' 30px 30px 20px 10px'}`
        }}>
            <div className={`${styles.innerContainer}  ${hideScroll ? styles.hideScroll : null}`}>
                {noHeader ? null : <header className={styles.moduleHeader}>
                    <div className={styles.title}>
                        {icon ? icon : <i className="fa-solid fa-puzzle" style={{
                            color: `var(--${color})`
                        }}></i>}
                        <h2>{title ? title : 'نام ماژول'}</h2>
                    </div>
                    <div className={styles.header}>
                        {header}
                    </div>
                </header>}
                <div className={styles.content}>
                    {children}
                </div>

            </div>
            <footer className={styles.footer}>
                {footer}
            </footer>
        </div>
    );
}
export default Module;