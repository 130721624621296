import styles from "../../pages/Authentication/AuthPage.module.css";
import { Button, Form, Input } from "../index";
import { forwardRef } from "react";
import { MasterLoginSchema } from "../../validation/AuthenticationSchema";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../services/APIs";

const MasterLogin = forwardRef(({ activeAgentLogin }, ref) => {
  // Initial field values of the form, Formik will make these values available to render methods component as values.
  const formInitialValues = { username: "", password: "" };

  // React-Router-Dom Navigation
  const navigate = useNavigate();

  // Login Function
  const handleLoginUser = (values) => {
    // navigate("/dashboard/home");
    loginUser(
      "super_admin",
      values.username,
      values.password,
      null,
      (res) => {
        localStorage.setItem("token", res.access);
        localStorage.setItem("refresh_token", res.refresh);
        localStorage.setItem("username", res.username);
        localStorage.setItem("password", values.password);
        navigate("/dashboard/home");
      },
      () => {}
    );
  };

  return (
    <div
      className={`${styles.layer} ${styles.loginLayer} ${styles.masterLogin}`}
      ref={ref}
    >
      <header className={styles.header}>
        <h1>پنل مدیریت</h1>
      </header>

      <div className={styles.loginType}>
        <p className={styles.loginTypeText}>ورود به عنوان :</p>
        <div className={styles.btnContainer}>
          <Button
            text={"توزیع کننده"}
            color={"gray"}
            onClickFunction={activeAgentLogin}
          />
          <Button text={"ادمین"} disable />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.bodyText}>
          <h1>خوش آمدید٬</h1>
          <br />
          <h3>برای ادامه وارد شوید.</h3>
        </div>

        <Form
          initialValues={formInitialValues}
          validationSchema={MasterLoginSchema}
          onSubmit={(values) => handleLoginUser(values)}
        >
          <Input placeHolder={"نام کاربری"} name={"username"} />
          <Input placeHolder={"رمز عبور"} type={"password"} name={"password"} />
          {/* <Input placeHolder={"کد لایسنس"} type={"text"} name={"license"} /> */}
          <Button
            text={"ورود"}
            formButton
            customClass={styles.signInBtn}
            submit
          />
        </Form>
      </div>
    </div>
  );
});
export default MasterLogin;
