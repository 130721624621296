import styles from "./AuthPage.module.css";
import { AgentLogin, MasterLogin } from "../../components";
import { useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*
    Authentication Main Component
*/
const AuthPage = () => {
  // Refs
  const AgentLoginRef = useRef();
  const MasterLoginRef = useRef();

  // Animation Delay in ms
  const AnimationDelay = 600;

  // Put Agent Login Card on top of the stack
  const activeAgentLogin = () => {
    MasterLoginRef.current.style.transition = `all ${AnimationDelay}ms`;
    AgentLoginRef.current.style.transition = `all ${AnimationDelay}ms`;

    // Animation Stage 1
    AgentLoginRef.current.style.transform = "translateX(110%)";
    AgentLoginRef.current.style.bottom = "0";
    MasterLoginRef.current.style.transform = "translateY(50px) scale(0.9)";

    // Animation Stage 2
    setTimeout(() => {
      AgentLoginRef.current.style.zIndex = "3";
      MasterLoginRef.current.style.zIndex = "2";
    }, AnimationDelay);

    // Animation Stage 3
    setTimeout(() => {
      AgentLoginRef.current.style.transform = "translateX(0)";
    }, AnimationDelay + 150);
  };

  // Put Agent Login Card on top of the stack
  const activeMasterLogin = () => {
    AgentLoginRef.current.style.transition = `all ${AnimationDelay}ms`;
    MasterLoginRef.current.style.transition = `all ${AnimationDelay}ms`;

    // Animation Stage 1
    MasterLoginRef.current.style.transform = "translateX(110%)";
    MasterLoginRef.current.style.bottom = "0";
    AgentLoginRef.current.style.transform = "translateY(50px) scale(0.9)";

    // Animation Stage 2
    setTimeout(() => {
      MasterLoginRef.current.style.zIndex = "3";
      AgentLoginRef.current.style.zIndex = "2";
    }, AnimationDelay);

    // Animation Stage 3
    setTimeout(() => {
      MasterLoginRef.current.style.transform = "translateX(0)";
    }, AnimationDelay + 150);
  };

  return (
    <section className={styles.container}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div className={styles.layer_container}>
        <AgentLogin activeMasterLogin={activeMasterLogin} ref={AgentLoginRef} />
        <MasterLogin activeAgentLogin={activeAgentLogin} ref={MasterLoginRef} />
        <div className={styles.layer}></div>
      </div>
    </section>
  );
};
export default AuthPage;
