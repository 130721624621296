import moment from "jalali-moment";
import { DashContent, LogBox, Module, Paginate } from "../../components";
import { getMarzbanRequestLogs } from "../../services/APIs";
import { storageUserInfo } from "../../services/auth";
import styles from "./LogsPage.module.css";
import { useEffect, useState } from "react";

/*
    Logs Page
*/
const LogsPage = () => {
  // States
  // Sample logs
  const [logs, setLogs] = useState([1, 2, 3, 4, 5]);
  const [currentItems, setCurrentItems] = useState([]);
  const userInfo = storageUserInfo();
  useEffect(() => {
    getMarzbanRequestLogs(
      `?admin_id=${userInfo?.username}`,
      (res) => setCurrentItems(res.items),
      (error) => {}
    );
  }, []);

  return (
    <DashContent>
      <Module
        width={100}
        height={100}
        title={"فعالیت ها"}
        icon={<i className="fa-regular fa-file-check"></i>}
        footer={<Paginate setCurrentItems={setCurrentItems} items={logs} />}
      >
        <div className={styles.logContainer}>
          {currentItems &&
            currentItems.map((log) => {
              return (
                <LogBox
                  key={log.id}
                  text={log.request_body}
                  date={moment(log.created_at).format("jYYYY/jMM/jDD")}
                  time={moment(log.created_at).locale("fa").format("HH:mm:ss")}
                />
              );
            })}
        </div>
      </Module>
    </DashContent>
  );
};
export default LogsPage;
