import * as yup from "yup";

export const AgentSchema = yup.object().shape({
  name: yup.string().required("نام کاربری الزامی می باشد."),
  username: yup.string().required("نام کاربری الزامی می باشد."),
  password: yup.string().required("کلمه عبور الزامی می باشد."),
  total_capacity: yup.number().required("ظرفیت الزامی می باشد."),
  // max_days: yup.number().nullable(),
  // cronjob: yup.boolean(),
  is_active: yup.boolean(),
});
