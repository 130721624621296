import {
  Badge,
  Button,
  ButtonContainer,
  CreatePanel,
  DashContent,
  Module,
  Paginate,
  PanelDetail,
  PanelsTable,
  Table,
} from "../../components";
import { useEffect, useRef, useState } from "react";
import SampleData from "../../Sample Data/PanelSample.json";
import { Link } from "react-router-dom";
import styles from "./PanelsPage.module.css";

/*
    Panels Page
*/
const PanelsPage = () => {
  // Refs
  const CreatePanelRef = useRef(null);
  const PanelDetailRef = useRef(null);

  // States
  const [createPopupStatus, setCreatePopupStatus] = useState(false);
  const [panelDetailPopupStatus, setPanelDetailPopupStatus] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState({});
  const [panels, setPanels] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  // Open Create Panel Popup
  const openCreatePanel = () => {
    setCreatePopupStatus(true);
  };

  // Open Panel Details Popup
  const openPanelDetail = (panel) => {
    setSelectedPanel(panel);
    setPanelDetailPopupStatus(true);
  };

  // restore all Panels function
  const restoreAllPanels = () => {
    console.log("Restore ALl Panels");
  };

  // Backup all Panels function
  const backupAllPanels = () => {
    console.log("backup All Panels");
  };

  // Fetch Sample Data From Sample JSON on load
  useEffect(() => {
    setPanels(SampleData);
  }, []);

  return (
    <DashContent>
      {/* POPUPS */}
      {createPopupStatus && (
        <CreatePanel
          ref={CreatePanelRef}
          setPopupStatus={setCreatePopupStatus}
        />
      )}

      {panelDetailPopupStatus && (
        <PanelDetail
          ref={PanelDetailRef}
          panel={selectedPanel}
          setPopupStatus={setPanelDetailPopupStatus}
        />
      )}

      <Module
        title={"پنل ها"}
        icon={<i className="fa-regular fa-panel-ews"></i>}
        height={100}
        header={
          <ButtonContainer customClass={styles.buttonContainer}>
            {/* <Button
              text={"ایجاد پنل"}
              color={"purple"}
              onClickFunction={openCreatePanel}
            /> */}
            {/* <Button text={'Restore All'} color={'purple'} onClickFunction={restoreAllPanels}/>
                    <Button text={'Backup All'} color={'purple'} onClickFunction={backupAllPanels}/> */}
          </ButtonContainer>
        }
        footer={
          <Paginate
            setCurrentItems={setCurrentItems}
            items={panels}
            itemsPerPage={1}
          />
        }
      >
        <PanelsTable panels={currentItems} openPanelDetail={openPanelDetail} />
      </Module>
    </DashContent>
  );
};
export default PanelsPage;
