import { aDel, aGet, aPost, aPut, postLogin } from "./requests";

export const loginUser = (
  type,
  username,
  password,
  XOwnerKey,
  onSuccess,
  onFailed
) => {
  let payload = { username: username, password: password, user_type: type };
  postLogin(
    `token/pair`,
    payload,
    XOwnerKey,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getUserInfo = (onSuccess, onFailed) => {
  aGet(
    "auth/me",
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getSubOrdinatesList = (queries = "", onSuccess, onFailed) => {
  aGet(
    "auth/admin" + queries,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const createSubOrdinate = (payload, onSuccess, onFailed) => {
  aPost(
    "auth/admin",
    payload,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getOneSubOrdinate = (username, onSuccess, onFailed) => {
  aGet(
    "auth/admin/" + username,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const putOneSubOrdinate = (username, payload, onSuccess, onFailed) => {
  aPut(
    "auth/admin/" + username,
    payload,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const deleteOneSubOrdinate = (username, onSuccess, onFailed) => {
  //marzban
  aDel(
    "auth/admin/" + username,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getOneSubOrdinateSubOrdinates = (
  username,
  queries,
  onSuccess,
  onFailed
) => {
  aGet(
    "auth/admin/" + username + "/subordinates" + queries,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getSubOrdinateReview = (username, onSuccess, onFailed) => {
  aPost(
    "auth/admin/" + username + "/review",
    null,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const postResetDataUsage = (username, onSuccess, onFailed) => {
  aPost(
    "auth/admin/" + username + "/reset-data-usage",
    null,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getInbounds = (onSuccess, onFailed) => {
  aGet(
    "system/inbounds",
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getUsers = (queries, onSuccess, onFailed) => {
  aGet(
    "user" + queries,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const postUser = (payload, onSuccess, onFailed) => {
  aPost(
    "user",
    payload,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getOneUser = (username, onSuccess, onFailed) => {
  aGet(
    "user/" + username,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const putUser = (username, payload, onSuccess, onFailed) => {
  aPut(
    "user/" + username,
    payload,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const deleteUser = (username, onSuccess, onFailed) => {
  aDel(
    "user/" + username,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const postResetUserUsage = (username, onSuccess, onFailed) => {
  aPost(
    "user/" + username + "/reset",
    null,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const postRevokeUserSubscription = (username, onSuccess, onFailed) => {
  aPost(
    "user/" + username + "/revoke_sub",
    null,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const postSetUserOwner = (username, payload, onSuccess, onFailed) => {
  aPost(
    "user/" + username + "/set-owner",
    payload,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getUserUsages = (username, queries, onSuccess, onFailed) => {
  aGet(
    "user/" + username + "/usage" + queries,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getMarzdashStats = (onSuccess, onFailed) => {
  aGet(
    "stats/marzdash",
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getOwnerStats = (onSuccess, onFailed) => {
  aGet(
    "stats/owner",
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getMarzbanStats = (onSuccess, onFailed) => {
  aGet(
    "stats/marzban",
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getCoreStats = (onSuccess, onFailed) => {
  aGet(
    "stats/core",
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getMyStats = (onSuccess, onFailed) => {
  aGet(
    "stats/me",
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};

export const getMarzbanRequestLogs = (queries, onSuccess, onFailed) => {
  aGet(
    "logs/marzban-request-logs" + queries,
    (res) => onSuccess(res),
    (error) => onFailed(error)
  );
};
