export const setToken = async (token) => {
  try {
    await localStorage.setItem("token", token);
  } catch (e) {}
};

export const getToken = async () => {
  try {
    const value = await localStorage.getItem("token");
    return value;
  } catch (e) {}
};

export const clearToken = async () => {
  try {
    await localStorage.clear();
  } catch (e) {}
};

export const storageUserInfo = () => {
  let userInfo = localStorage.getItem("userInfo");
  return JSON.parse(userInfo);
};
