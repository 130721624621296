import styles from "./DashboardPage.module.css";
import navStyles from "../../components/Navbar/Navbar.module.css";
import { Route, Routes } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  AdminHomePage,
  AgentsPage,
  Header,
  LogsPage,
  Navbar,
  PanelsPage,
  PlansPage,
  ResellerHomePage,
  SettingsPage,
} from "../../components";

/*
    Dashboard Base Component 
*/
const DashboardPage = () => {
  // Refs
  const navRef = useRef(null);
  const closeIconRef = useRef(null);
  const openIconRef = useRef(null);

  // States
  const [navStatus, setNavStatus] = useState("open");
  const [size, setSize] = useState(window.innerWidth);
  const userInfo = localStorage.getItem("userInfo");

  // Toggle Navbar status ( close / open )
  const toggleNavbar = (situation) => {
    if (navStatus === "open" || situation === "close") {
      navRef.current.classList.add(navStyles.close);
      closeIconRef.current.style.display = "flex";
      openIconRef.current.style.display = "none";
      setNavStatus("close");
    } else if (navStatus === "close" || situation === "open") {
      navRef.current.classList.remove(navStyles.close);
      closeIconRef.current.style.display = "none";
      openIconRef.current.style.display = "flex";
      setNavStatus("open");
    }
  };

  // Responsiveness

  // Set Screen size state on window size change
  useEffect(() => {
    const updateWindowDimensions = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  // Close navbar by default in medium-scaled devices and small-scaled devices
  useEffect(() => {
    if (size < 768) {
      toggleNavbar("close");
    } else {
      // eslint-disable-next-line no-unused-expressions
      navStatus === "close" ? toggleNavbar("open") : null;
    }
  }, [size]);

  return (
    <section className={styles.container}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        className={styles.toastContainer}
        style={
          size > 576
            ? {
                top: "100px",
                right: navStatus === "open" ? "90px" : "-65px",
              }
            : {
                top: "100px",
                padding: "0 20px",
              }
        }
      />
      <div className={styles.navContainer}>
        <Navbar
          ToggleNavbar={toggleNavbar}
          size={size}
          ref={{ navRef, openIconRef, closeIconRef }}
          navStatus={navStatus}
          setNavStatus={setNavStatus}
        />
      </div>
      <section className={styles.contentContainer}>
        <Header ToggleNavbar={toggleNavbar} />
        <section className={styles.content}>
          <Routes>
            {/* Admin Side */}
            <Route element={<AdminHomePage />} index={true} />
            <Route element={<AdminHomePage />} path={"home"} index={true} />
            {/* <Route element={<PlansPage />} path={"plans"} /> */}
            <Route element={<AgentsPage />} path={"agents"} />
            {/* <Route element={<SettingsPage />} path={"settings"} /> */}
            <Route element={<LogsPage />} path={"Logs"} />
            <Route element={<PanelsPage />} path={"panels"} />

            {/* Reseller Side */}
            <Route element={<ResellerHomePage />} path={"reseller"} />
          </Routes>
        </section>
      </section>
    </section>
  );
};
export default DashboardPage;
