import styles from './Button.module.css';
import {useState} from "react";

/*
    All kind of buttons
*/
const Button = ({customClass, text, formButton, onClickFunction, disable, to, color,submit}) => {

    // Mouse Hovering status state
    const [isHover, setIsHover] = useState(false)

    // Changing Hover status state
    const handleMouseEnter = () => {
        setIsHover(true)
    }
    // Changing Hover status state
    const handleMouseLeave = () => {
        setIsHover(false)
    }
    return (
        <>
            <button className={`${styles.button} ${customClass} ${formButton ? styles.form : null} ${submit ? styles.submit : null}`}
                  onClick={onClickFunction}
                  style={{
                      pointerEvents: disable ? "none" : "unset",
                      backgroundColor: isHover ? `var(--${color}` : `var(--${color}-bg`,
                      color: isHover ? color==='gray' ? '#000' : `#fff` : `var(--${color}`,

                  }} to={to ? to : null}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  type={submit ? "submit" : "button"}>
                {text}
            </button>
            <style>

            </style>
        </>
    );
}
export default Button;