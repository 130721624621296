import styles from './Popup.module.css';
import {forwardRef, useCallback, useEffect} from "react";
import ClosePopup from "./ClosePopup";
import closePopup from "./ClosePopup";

/*
    Popup Module
*/
const Popup = forwardRef(({setPopupStatus, title, icon, children}, ref) => {

    // Close Popup on esc key
    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            closePopup(ref, setPopupStatus)
        }
    }, []);


    // Event Listener for closing Popup on esc key pressed
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);


    return (
        <div className={styles.container} ref={ref}>
            <div className={styles.innerContainer}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        {icon ? icon : <i className="fa-light fa-square-code"></i>}
                        <p>{title ? title : 'عنوان'}</p>
                    </div>
                    <div className={styles.close} onClick={() => ClosePopup(ref, setPopupStatus)}><i
                        className="fa-regular fa-xmark"></i></div>
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
})
export default Popup;