import styles from "./Header.module.css";
import { HeaderItem, HeaderItemContainer, LangSelector } from "../index";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../services/APIs";

/*
    Dashboard Header Main Component
*/
const Header = ({ ToggleNavbar }) => {
  // Refs
  const MobileConfigMenuRef = useRef(null);
  const [userInfo, setUserInfo] = useState();

  // React-Router-Dom Navigation
  const navigate = useNavigate();

  // request fullscreen mode. -not work in apple devices
  const requestFullscreen = () => {
    try {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Active Dashboard configuration menu in small-scaled devices
  const activeMobileConfigMenu = () => {
    MobileConfigMenuRef.current.classList.toggle(styles.active);
  };

  // Change Dashboard Theme -Not developed
  const changeTheme = () => {};

  // Logout Function
  const logOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    getUserInfo(
      (res) => {
        setUserInfo(res);
        localStorage.setItem("userInfo", JSON.stringify(res));
      },
      (error) => {}
    );
  }, []);
  return (
    <header className={styles.header}>
      <HeaderItemContainer ref={MobileConfigMenuRef}>
        <Tooltip anchorSelect={".logout"}>خروج</Tooltip>
        <HeaderItem
          icon={<i className="fa-regular fa-arrow-right-from-bracket"></i>}
          customClass={`logout ${styles.logout}`}
          onClickFunction={logOut}
        />
        <Tooltip anchorSelect={".lang"} place={"top"}>
          انتخاب زبان
        </Tooltip>
        <HeaderItem
          icon={
            <img
              src={require("../../static/iran.png")}
              alt={"iran-flag"}
              className={"lang"}
            />
          }
          dropDown={<LangSelector />}
        />

        <Tooltip anchorSelect={".theme"}>تم</Tooltip>
        <HeaderItem
          icon={<i className="fa-regular fa-moon"></i>}
          customClass={"theme"}
          onClickFunction={changeTheme}
        />

        <Tooltip anchorSelect={".fullscreen"}>تمام صفحه</Tooltip>
        <HeaderItem
          icon={<i className="fa-regular fa-expand"></i>}
          onClickFunction={requestFullscreen}
          customClass={"fullscreen"}
        />

        <HeaderItem
          icon={<i className="fa-regular fa-bars"></i>}
          onClickFunction={ToggleNavbar}
          mobile
        />
        <HeaderItem
          icon={<i className="fa-regular fa-sliders"></i>}
          onClickFunction={activeMobileConfigMenu}
          mobile
        />
      </HeaderItemContainer>

      <div className={styles.user}>
        <div className={styles.userDetail}>
          <h4>{userInfo?.full_name ?? ""}</h4>
          <p>{userInfo?.type === "super_admin" ? "ادمین" : "توزیع کننده"}</p>
        </div>
        <div className={styles.userIcon}>
          <img
            src={require("../../static/avatars/curly-hair-man.png")}
            alt="avatar"
          />
        </div>
      </div>
    </header>
  );
};
export default Header;
