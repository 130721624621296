import {forwardRef} from "react";
import {Button, Form, Input, Popup} from "../index";
import {PanelSchema} from "../../validation/PanelSchema";
import closePopup from "../Popup/ClosePopup";
import {toast} from "react-toastify";


/*
    Create new Panel Popup
*/
const CreatePanel = forwardRef(({setPopupStatus}, ref) => {
    
    
    // Initial field values of the form, Formik will make these values available to render methods component as values.
    const formInitialValues = {
        name: null,
        username: null,
        password: null,
        ip: null
    }

    // Create new Panel function
    const createNewPanel = (values) => {
        console.log(values);
        closePopup(ref, setPopupStatus);

        // Panel Created successfully notification
        toast.success('پنل با موفقیت ساخته شد.')
    }

    return (
        <Popup title={'ایجاد پنل جدید'} icon={<i className="fa-regular fa-plus"></i>} ref={ref}
               setPopupStatus={setPopupStatus}>
            <Form validationSchema={PanelSchema} initialValues={formInitialValues}
                  onSubmit={(values) => createNewPanel(values)}>
                <Input placeHolder={'نام'} type={'text'} name={'name'}/>
                <Input placeHolder={'نام کاربری مرزبان'} type={'text'} name={'username'}/>
                <Input placeHolder={'کلمه عبور'} type={'password'} name={'password'}/>
                <Input placeHolder={'IP / URL'} type={'text'} name={'ip'}/>
                <Button text={'ایجاد'} formButton submit/>
            </Form>
        </Popup>
    );
})
export default CreatePanel;

