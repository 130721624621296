import { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Input50,
  Popup,
  Switch,
} from "../index";
import styles from "./User.module.css";
import { Tooltip } from "react-tooltip";
import { UserDetailSchema } from "../../validation/UserDetailSchema";
import closePopup from "../Popup/ClosePopup";
import { toast } from "react-toastify";
import {
  deleteUser,
  postResetUserUsage,
  postRevokeUserSubscription,
  putUser,
} from "../../services/APIs";
import moment from "jalali-moment";

/*
    User Detail Popup 
*/
const UserDetail = forwardRef(({ setPopupStatus, user, getAllUsers }, ref) => {
  // States
  const [onHoldOption, setOnHoldOption] = useState(false);

  // Change Input type based on user on_hold field
  useEffect(() => {
    if (user.on_hold) setOnHoldOption(true);
  }, []);

  // Initial field values of the form, Formik will make these values available to render methods component as values.
  const formInitialValues = {
    username: user.username,
    data_limit: parseInt(user.data_limit / (1024 * 1024 * 1024)),
    expiry_date: user.expiry_date,
    hold_days: user.hold_days,
    on_hold: user.on_hold,
    is_active: user.is_active,
  };

  // Change User detail function
  const changeDetail = (values) => {
    let payload = values.on_hold
      ? {
          auto_delete_in_days: -1,
          data_limit: parseInt(values.data_limit) * 1024 * 1024 * 1024,
          data_limit_reset_strategy: "no_reset",
          expire: 0,
          inbounds: user.inbounds,
          note: "",
          on_hold_expire_duration: values.on_hold
            ? parseInt(values.hold_days) * 86400
            : 0,
          on_hold_timeout: "2025-11-03T20:30:00",
          proxies: {
            vless: {},
            vmess: {
              id: "35e4e39c-7d5c-4f4b-8b71-558e4f37ff53",
            },
          },
          status: values.on_hold ? "on_hold" : "active",
          username: values.username,
          is_active: values.is_active,
        }
      : {
          auto_delete_in_days: -1,
          data_limit: parseInt(values.data_limit) * 1024 * 1024 * 1024,
          data_limit_reset_strategy: "no_reset",
          expire: !values.on_hold
            ? new Date(
                moment(values.expiry_date).format("YYYY-MM-DD")
              ).getTime() / 1000
            : 0,
          inbounds: user.inbounds,
          note: "",
          proxies: {
            vless: {},
            vmess: {
              id: "35e4e39c-7d5c-4f4b-8b71-558e4f37ff53",
            },
          },
          status: values.on_hold ? "on_hold" : "active",
          username: values.username,
          is_active: values.is_active,
        };
    putUser(
      user.username,
      payload,
      (res) => {
        closePopup(ref, setPopupStatus);
        toast.success("تغییرات با موفقیت ذخیره شد.");
        getAllUsers();
      },
      (error) => {
        toast.error("مشکلی پیش آمده است.");
      }
    );
  };

  const handleReset = () => {
    postResetUserUsage(
      user.username,
      () => {
        closePopup(ref, setPopupStatus);
        toast.success("تغییرات با موفقیت ذخیره شد.");
        getAllUsers();
      },
      (error) => {
        toast.error("مشکلی پیش آمده است.");
      }
    );
  };

  const handleRevokeSub = () => {
    postRevokeUserSubscription(
      user.username,
      () => {
        closePopup(ref, setPopupStatus);
        toast.success("تغییرات با موفقیت ذخیره شد.");
        getAllUsers();
      },
      (error) => {
        toast.error("مشکلی پیش آمده است.");
      }
    );
  };

  const handleDelete = () => {
    deleteUser(
      user.username,
      () => {
        closePopup(ref, setPopupStatus);
        toast.success("کاربر با موفقیت حذف شد.");
        getAllUsers();
      },
      (error) => {
        toast.error("مشکلی پیش آمده است.");
      }
    );
  };

  return (
    <Popup
      setPopupStatus={setPopupStatus}
      ref={ref}
      title={user.username}
      icon={<i className="fa-solid fa-user"></i>}
    >
      <Form
        validationSchema={UserDetailSchema}
        initialValues={formInitialValues}
        onSubmit={(values) => changeDetail(values)}
      >
        <Input50>
          <Input
            placeHolder={"نام کاربری"}
            value={user.username}
            name={"username"}
          />
          <Switch
            label={"فعال / غیرفعال"}
            checked={user.is_active}
            name={"is_active"}
          />
        </Input50>
        <Input
          placeHolder={"حجم"}
          value={user.data_limit}
          name={"data_limit"}
        />

        <Input50>
          <Checkbox
            text={"On Hold"}
            state={onHoldOption}
            setState={setOnHoldOption}
            name={"on_hold"}
          />
          {onHoldOption ? (
            <Input
              placeHolder={"روز"}
              value={user.hold_days}
              name={"hold_days"}
            />
          ) : null}
          {!onHoldOption ? (
            <Input
              placeHolder={"تاریخ انقضا"}
              value={user.expiry_date}
              name={"expiry_date"}
              isDate
            />
          ) : null}
        </Input50>

        <Input50>
          <Button
            text={"reset usage"}
            color={"blue"}
            onClickFunction={handleReset}
          />
          <Button
            text={"revoke subscription"}
            color={"purple"}
            onClickFunction={handleRevokeSub}
          />
        </Input50>

        <Input50 customclass={styles.footer}>
          <Button text={"ثبت تغییرات"} formButton submit />
          <div className={styles.footerContainer} onClick={handleDelete}>
            <Tooltip anchorSelect={".delete"}>Delete</Tooltip>
            <span className={"delete"}>
              <i className="fa-regular fa-trash"></i>
            </span>
          </div>
        </Input50>
      </Form>
    </Popup>
  );
});
export default UserDetail;
