import styles from './Header.module.css';
import {useRef} from "react";

/*
    Header item boxes 
*/
const HeaderItem = ({icon, dropDown, onClickFunction, mobile, customClass}) => {

    // Dropdown Ref
    const dropDownRef = useRef();


    // toggle item Dropdown
    const toggleDropDown = () => {
        try {
            dropDownRef.current.classList.toggle(styles.active)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>

            <div
                className={`${styles.headerItem} ${mobile ? styles.mobileItem : styles.largeDeviceItem} ${customClass}`}>
                {dropDown ? <div className={styles.dropDownContainer} ref={dropDownRef}
                                 onMouseLeave={toggleDropDown}>{dropDown}</div> : null}
                <div className={styles.headerItemIcon} onClick={onClickFunction ? onClickFunction : toggleDropDown}>
                    {icon}
                </div>
            </div>
        </>
    );
}
export default HeaderItem;