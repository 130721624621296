import {
  Button,
  Checkbox,
  Form,
  Input,
  Input50,
  Popup,
  Switch,
} from "../index";
import styles from "./Agent.module.css";
import { forwardRef, useEffect, useState } from "react";
import { AgentSchema } from "../../validation/AgentSchema";
import closePopup from "../Popup/ClosePopup";
import { toast } from "react-toastify";
import { createSubOrdinate, getInbounds } from "../../services/APIs";

/*
    Add a new Agent Popup
*/
const AddAgent = forwardRef(({ setPopupStatus, getSubordinates }, ref) => {
  // Initial field values of the form, Formik will make these values available to render methods component as values.

  const [inbounds, setInbounds] = useState([]);
  const formInitialValues = {
    name: null,
    username: null,
    password: null,
    total_capacity: null,
    // max_days: null,
    // cronjob: false,
    is_active: false,
  };

  useEffect(() => {
    getInbounds(
      (res) => {
        let allInbounds = [
          ...res.shadowsocks,
          ...res.trojan,
          ...res.vless,
          ...res.vmess,
        ];
        let locInbounds = [];
        for (let i = 0; allInbounds.length; i++) {
          locInbounds = [
            ...locInbounds,
            {
              value: i,
              label: allInbounds[i].tag,
              type: allInbounds[i].protocol,
            },
          ];
          if (i === allInbounds.length - 1) {
            setInbounds(locInbounds);
          }
        }
      },
      (error) => {}
    );
  }, []);

  // Creating new Agent function
  const createAgent = (values) => {
    let locInbounds = [];
    for (let i = 0; i < inbounds.length; i++) {
      locInbounds = [...locInbounds, inbounds[i].label];
    }
    let payload = {
      notes: "",
      first_name: values.name,
      last_name: "",
      address: "",
      referrer: "",
      phone_number: "",
      telegram_id: 0,
      discord_webhook: "",
      max_subordinates_count: 100,
      max_created_users_count: 100,
      max_subordinates_traffic: 0,
      max_used_traffic: values.total_capacity * 1024 * 1024 * 1024,
      min_user_data_limit: 0,
      max_user_data_limit: 0,
      max_user_duration: "P3D",
      user_username_prefix: "",
      user_username_suffix: "",
      expires_at: "2024-08-20T10:36:39.003Z",
      inbounds: locInbounds,
      password: values.password,
      marzban_username: "admin",
      marzban_password: "admin",
      panel_address: "https://example.com/",
      username: values.username,
      type: "admin",
      is_active: values.is_active,
    };
    createSubOrdinate(
      payload,
      (res) => {
        getSubordinates();
        closePopup(ref, setPopupStatus);
        toast.success("نماینده جدید با موفقیت افزوده شد.");
      },
      (error) => toast.error("مشکلی پیش آمده است.")
    );

    // Agent added successfully notification
  };

  return (
    <Popup
      title={"افزودن نماینده جدید"}
      icon={<i className="fa-regular fa-plus"></i>}
      setPopupStatus={setPopupStatus}
      ref={ref}
    >
      <Form
        validationSchema={AgentSchema}
        initialValues={formInitialValues}
        onSubmit={(values) => createAgent(values)}
      >
        <Input placeHolder={"نام"} type={"text"} name={"name"} />
        <Input placeHolder={"نام کاربری"} type={"text"} name={"username"} />
        <Input placeHolder={"کلمه عبور"} type={"password"} name={"password"} />
        <Input placeHolder={"ظرفیت"} type={"number"} name={"total_capacity"} />
        {/* <Input50>
          <Checkbox text={"کرون جاب"} name={"cronjob"} />
          <Input placeHolder={"حداکثر روز"} type={"number"} name={"max_days"} />
        </Input50> */}
        <Input50 customclass={styles.footer}>
          <Button text={"افزودن"} formButton submit />
          <div className={styles.footerContainer}>
            <Switch label={"فعال / غیرفعال"} name={"is_active"} />
          </div>
        </Input50>
      </Form>
    </Popup>
  );
});
export default AddAgent;
