import styles from "./Form.module.css";
import Select from "react-select";
import { useEffect, useRef } from "react";
import { Field, getIn, useFormikContext } from "formik";

/*
    Select input field using 'react-select' module
*/
const CustomSelect = ({ placeHolder, options, isMulti, value, name }) => {
  // Refs
  const placeHolderRef = useRef();
  const selectRef = useRef();
  // Formik states and helpers via React Context.
  const formikProps = useFormikContext();

  // Active PlaceHolder effect if value has value on load
  useEffect(() => {
    if (value) activeFocus();
  }, []);

  // Active PlaceHolder Focus effect
  const activeFocus = () => {
    placeHolderRef.current.classList.add(styles.activePlaceHolder);
  };

  // Set the value of a field imperatively.
  const setFieldValue = (field, data) => {
    formikProps.setFieldValue(field, data);
  };

  // Change Input styles on Error
  const getStyles = (errors, fieldName) => {
    if (getIn(errors, fieldName)) {
      return {
        border: "2px solid var(--red)",
        boxShadow: "none",
      };
    }
  };

  return (
    <div
      className={styles.selectContainer}
      style={{
        height: isMulti ? "60px" : "50px",
      }}
    >
      <p
        className={styles.selectPlaceHolder}
        ref={placeHolderRef}
        style={{
          top: isMulti ? "20px" : "16px",
        }}
      >
        {placeHolder}
      </p>

      <Field name={name}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { errors },
          meta,
        }) => (
          <>
            <div
              style={getStyles(errors, name)}
              className={styles.selectWrapper}
            >
              <Select
                options={options}
                isSearchable
                placeholder={""}
                defaultValue={value}
                ref={selectRef}
                classNames={{
                  control: ({ isFocused }) => {
                    if (isFocused) {
                      if (isMulti)
                        return `${styles.select} ${styles.selectMulti} ${styles.selectFocus}`;
                      else return `${styles.select} ${styles.selectFocus}`;
                    }
                    if (isMulti)
                      return `${styles.select} ${styles.selectMulti}`;
                    else return styles.select;
                  },
                  menu: () => styles.selectMenu,
                  option: ({ isSelected, isFocused }) => {
                    if (isSelected)
                      return `${styles.selectOptionSelect} ${styles.selectOption}`;
                    if (isFocused)
                      return `${styles.selectOptionHover} ${styles.selectOption}`;
                    else return `${styles.selectOption}`;
                  },
                  placeholder: () => styles.selectPlaceHolder,
                  indicatorSeparator: () => styles.selectIndicatorSeparator,
                  multiValue: () => styles.selectMultiValue,
                  multiValueRemove: () => styles.selectMultiValueRemove,
                  valueContainer: () =>
                    isMulti
                      ? styles.valueContainer
                      : styles.valueContainerSingle,
                }}
                noOptionsMessage={() => "گزینه ای وجود ندارد"}
                isMulti={isMulti}
                onFocus={activeFocus}
                closeMenuOnSelect={!isMulti}
                onChange={(data) => setFieldValue(field.name, data)}
              />
            </div>
            {meta.error && <div className={styles.error}>{meta.error}</div>}
          </>
        )}
      </Field>
    </div>
  );
};
export default CustomSelect;
