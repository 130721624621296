import {forwardRef} from "react";
import {Button, Form, Input, Popup} from "../index";
import {PanelSchema} from "../../validation/PanelSchema";
import closePopup from "../Popup/ClosePopup";
import {toast} from "react-toastify";

/*
    Panel Detail Popup
*/
const PanelDetail = forwardRef(({setPopupStatus, panel}, ref) => {

    // Initial field values of the form, Formik will make these values available to render methods component as values.
    const formInitialValues = {
        name: panel.name,
        username: panel.username,
        password: panel.password,
        ip: panel.ip
    }

    // Edit Panel Function
    const EditPanel = (values) => {
        console.log(values);
        closePopup(ref, setPopupStatus);

        // Details changed successfully notification
        toast.success('تغییرات با موفقیت اعمال شد.')
    }
    return (
        <Popup setPopupStatus={setPopupStatus} icon={<i className="fa-regular fa-server"></i>} ref={ref}
               title={panel.name}>
            <Form initialValues={formInitialValues} validationSchema={PanelSchema} onSubmit={(values)=> EditPanel(values)}>
                <Input placeHolder={'نام'} type={'text'} name={'name'} value={panel.name}/>
                <Input placeHolder={'نام کاربری مرزبان'} type={'text'} name={'username'} value={panel.username}/>
                <Input placeHolder={'کلمه عبور'} type={'password'} name={'password'} value={panel.password}/>
                <Input placeHolder={'IP / URL'} type={'text'} name={'ip'} value={panel.ip}/>
                <Button text={'ذخیره'} formButton submit/>
            </Form>
        </Popup>
    );
})
export default PanelDetail;