import styles from "./Logs.module.css";

/*
    Logs
*/
const LogBox = ({ text, time, date, key }) => {
  return (
    <div className={styles.container} key={key}>
      <p className={styles.text}>{text ? text : "بدون متن"}</p>
      <div className={styles.dateTime}>
        <p className={styles.time}>{time ? time : "12:34"}</p>
        <p className={styles.date}>{date ? date : "1/23/2024"}</p>
      </div>
    </div>
  );
};
export default LogBox;
