import styles from "./StatsModule.module.css";

/*
    Statistical cards Container
*/
const StatsContainer = ({children,customClass}) => {
    return (
        <div className={`${styles.statsContainer} ${customClass}`}>
            {children}
        </div>
    );
}
export default StatsContainer;