import * as yup from "yup";

export const CreateUserSchema = yup.object().shape({
  username: yup.string().required("نام کاربری الزامی می باشد."),
  data_limit: yup
    .number()
    .required("حجم الزامی می باشد.")
    .typeError("مقادیر ورودی میبایست عدد باشد."),
  expiry_date: yup.string().required("زمان انقضا الزامی می باشد."),
  countries: yup
    .array()
    .required("انتخاب کشور الزامی می باشد.")
    .min(1, "شما میبایست حداقل یک گزینه را انتخاب کنید."),
  on_hold: yup.boolean(),
  hold_days: yup
    .number()
    .typeError("مقادیر ورودی میبایست عدد باشد.")
    .nullable()
    .required("این فیلد الزامی میباشد."),
});
