import styles from "./Navbar.module.css";
import { NavItem } from "../index";
import { forwardRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { storageUserInfo } from "../../services/auth";

/*
    Dashboard Side navbar main component
*/
const Navbar = forwardRef(({ ToggleNavbar, navStatus, size }, ref) => {
  // Forwarding multiple Refs
  const { navRef, openIconRef, closeIconRef } = ref;
  const userInfo = storageUserInfo();
  // Close Navbar on CLicking on Nav items in medium-scaled devices
  const closeNav = () => {
    if (size < 768) {
      ToggleNavbar("close");
    }
  };

  return (
    <nav className={`${styles.navbar}`} ref={navRef}>
      <div className={styles.header}>
        <p>داشبورد</p>

        <Tooltip anchorSelect={".closeNav"} place={"bottom"}>
          بستن منو
        </Tooltip>
        <div
          className={`${styles.roll} closeNav`}
          onClick={ToggleNavbar}
          ref={openIconRef}
        >
          <i className={`fa-light fa-arrow-right-to-line`}></i>
        </div>
        <Tooltip anchorSelect={".openNav"}>باز کردن منو</Tooltip>
        <div
          className={`${styles.roll} ${styles.openIcon} openNav`}
          onClick={ToggleNavbar}
          ref={closeIconRef}
        >
          <i className={`fa-light fa-arrow-left-to-line`}></i>
        </div>
      </div>
      <div className={styles.itemContainer}>
        {userInfo?.type === "super_admin" ||
        window.location.href.includes("home") ? (
          <>
            <NavItem
              navStatus={navStatus}
              text={"خانه  (ادمین)"}
              to={"home"}
              ToggleNavbar={closeNav}
            >
              {" "}
              <i className="fa-regular fa-house"></i>
            </NavItem>

            {/* <NavItem
              navStatus={navStatus}
              text={"پلن ها"}
              to={"plans"}
              ToggleNavbar={closeNav}
            >
              <i className="fa-regular fa-server"></i>
            </NavItem> */}
            <NavItem
              navStatus={navStatus}
              text={"پنل ها"}
              to={"panels"}
              ToggleNavbar={closeNav}
            >
              <i className="fa-regular fa-panel-ews"></i>
            </NavItem>
            <NavItem
              navStatus={navStatus}
              text={"نماینده ها"}
              to={"agents"}
              ToggleNavbar={closeNav}
            >
              <i className="fa-solid fa-users"></i>
            </NavItem>
            {/* <NavItem
              navStatus={navStatus}
              text={"تنظیمات"}
              to={"settings"}
              ToggleNavbar={closeNav}
            >
              <i className="fa-regular fa-gear"></i>
            </NavItem> */}
            <NavItem
              navStatus={navStatus}
              text={"فعالیت ها"}
              to={"Logs"}
              ToggleNavbar={closeNav}
            >
              <i className="fa-regular fa-memo-circle-info"></i>
            </NavItem>
          </>
        ) : (
          <NavItem
            navStatus={navStatus}
            text={"خانه (توزیع کننده)"}
            to={"reseller"}
            ToggleNavbar={closeNav}
          ></NavItem>
        )}
      </div>
    </nav>
  );
});
export default Navbar;
