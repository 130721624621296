import styles from "./Pagination.module.css";
import selectStyles from "../Forms/Form.module.css";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import Select from "react-select";

/*
    Pagination Module with 2 parts
    Page switcher - Item Per Page selector
    using 'react-paginate' module
*/
const Paginate = ({ items, setCurrentItems }) => {
  // Default items per page
  const defaultItemPerPage = 4;

  // States
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemPerPage);

  // Items per page options
  const itemPerPageOptions = [
    { value: 2, label: 2 },
    { value: 4, label: 4 },
    { value: 6, label: 6 },
    { value: 8, label: 8 },
    { value: 10, label: 10 },
    { value: 20, label: 20 },
  ];

  // Change current page items state on change page
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
  }, [itemOffset, items, itemsPerPage]);

  // Count number of pages
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  // Change page items count
  const changeItemPerPage = (value) => {
    setItemsPerPage(value);
  };

  return (
    <div className={styles.container}>
      <ReactPaginate
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        breakLabel="..."
        nextLabel={<i className="fa-regular fa-chevron-right"></i>}
        previousLabel={<i className="fa-regular fa-chevron-left"></i>}
        renderOnZeroPageCount={null}
        containerClassName={styles.paginate}
        pageClassName={styles.pageLink}
        previousClassName={styles.pageLink}
        nextClassName={styles.pageLink}
        breakClassName={styles.pageLink}
        activeClassName={styles.active}
        disabledClassName={styles.disable}
        onPageChange={handlePageClick}
        pageCount={pageCount}
      />

      <Select
        options={itemPerPageOptions}
        isSearchable
        placeholder={""}
        classNames={{
          control: ({ isFocused }) => {
            if (isFocused)
              return `${selectStyles.select} ${selectStyles.selectFocus} ${styles.select}`;
            else return `${selectStyles.select} ${styles.select}`;
          },
          menu: () => selectStyles.selectMenu,
          option: ({ isSelected, isFocused }) => {
            if (isSelected)
              return `${selectStyles.selectOptionSelect} ${selectStyles.selectOption}`;
            if (isFocused)
              return `${selectStyles.selectOptionHover} ${selectStyles.selectOption}`;
            else return `${selectStyles.selectOption}`;
          },
          placeholder: () => selectStyles.selectPlaceHolder,
          indicatorSeparator: () => selectStyles.selectIndicatorSeparator,
        }}
        noOptionsMessage={() => "گزینه ای وجود ندارد"}
        menuPlacement={"top"}
        onChange={(value) => changeItemPerPage(value.value)}
        defaultValue={{ value: defaultItemPerPage, label: defaultItemPerPage }}
      />
    </div>
  );
};
export default Paginate;
