import styles from "./Header.module.css";
import {forwardRef} from "react";

/*
    Header items container
*/
const HeaderItemContainer = forwardRef(({children}, ref) => {

    return(
        <div className={styles.itemContainer}>
            {children}
            <div className={`${styles.header} ${styles.mobileItemContainer}`} ref={ref}>
                <div className={styles.itemContainer}>
                    {/* eslint-disable-next-line array-callback-return */}
                    {children.map((child) => {
                        if (child.props.mobile === undefined || child.props.mobile === false){
                            return(child)
                        }
                    })}
                </div>
            </div>
        </div>
    );
})
export default HeaderItemContainer;