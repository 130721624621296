import {Link} from "react-router-dom";
import styles from "./Header.module.css";
import HeaderDropDown from "./HeaderDropDown";

/*
    Dashboard Language Selector Dropdown component
*/
const LangSelector = () => {
    return (
        <HeaderDropDown title={'انتخاب زبان'}>
            <Link className={styles.langItem}>
                <img src={require('../../static/iran.png')} alt="iran-flag"/>
                فارسی
            </Link>
            <Link className={styles.langItem}>
                <img src={require('../../static/usa.png')} alt="usa-flag"/>
                English
            </Link>
        </HeaderDropDown>
    );
}
export default LangSelector;