import * as yup from "yup";

export const AgentLoginSchema = yup.object().shape({
  username: yup.string().required("نام کاربری الزامی می باشد."),
  password: yup.string().required("کلمه عبور الزامی می باشد."),
  //   license: yup.string(),
});

export const MasterLoginSchema = yup.object().shape({
  username: yup.string().required("نام کاربری الزامی می باشد."),
  password: yup.string().required("کلمه عبور الزامی می باشد."),
  // license : yup.string().required('لایسنس کد الزامی می باشد.'),
});
