import styles from './Module.module.css';

/*
    Button Container used in module headers
*/
const ButtonContainer = ({customClass,children}) => {
    return(
        <div className={`${styles.buttonContainer} ${customClass}`}>
            {children}
        </div>
    );
}
export default ButtonContainer;