import Axios from "axios";
import { getToken } from "./auth";

export const BASE_URL = "http://5.75.206.170:8000/";

const wrapRequest = (request, onSuccess, onFailed) => {
  request
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202 ||
        response.status === 203 ||
        response.status === 204
      ) {
        onSuccess(response.data);
      } else onFailed();
    })
    .catch((reason) => {
      if (
        reason?.response?.status === 401 &&
        !window.location.href.includes("login")
      ) {
        localStorage.clear();
        window.location.replace("/login");
      }
      onFailed(reason);
    });
};
const getAuthConfig = (token) => {
  return {
    headers: {
      // Accept: "xml/application",
      // "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
  };
};

// const getAuthConfig = (token) => {
//   return {
//     headers: {
//       Accept: "xml/application",
//       "Access-Control-Allow-Origin": "*",
//       "Content-Type": "multipart/form-data",
//       Authorization: "Token " + token,
//     },
//   };
// };

export const getLoginHeader = (XOwnerCode) => {
  return {
    headers: {
      Accept: "xml/application",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Owner-Key": XOwnerCode,
    },
  };
};

export const postLogin = (url, data, XOwnerCode, onSuccess, onFailed) => {
  wrapRequest(
    Axios.post(BASE_URL + url, data, getLoginHeader(XOwnerCode)),
    (res) => {
      onSuccess(res);
    },
    onFailed
  );
};

export const get = (url, onSuccess, onFailed) => {
  wrapRequest(Axios.get(BASE_URL + url), onSuccess, onFailed);
};

export const post = (url, data, onSuccess, onFailed) => {
  wrapRequest(
    Axios.post(BASE_URL + url, data),
    (res) => {
      onSuccess(res);
    },
    onFailed
  );
};

export const put = (url, data, onSuccess, onFailed) => {
  wrapRequest(
    Axios.put(BASE_URL + url, data),
    (res) => {
      onSuccess(res);
    },
    onFailed
  );
};

export const del = (url, onSuccess, onFailed) => {
  wrapRequest(
    Axios.delete(BASE_URL + url),
    (res) => {
      onSuccess(res);
    },
    onFailed
  );
};

export const aGet = (url, onSuccess, onFailed) => {
  getToken().then((value) =>
    wrapRequest(
      Axios.get(BASE_URL + url, getAuthConfig(value)),
      onSuccess,
      onFailed
    )
  );
};

export const aPost = (url, data, onSuccess, onFailed) => {
  getToken().then((value) =>
    wrapRequest(
      Axios.post(BASE_URL + url, data, getAuthConfig(value)),
      (res) => {
        onSuccess(res);
      },
      onFailed
    )
  );
};

export const aPut = (url, data, onSuccess, onFailed) => {
  getToken().then((value) =>
    wrapRequest(
      Axios.put(BASE_URL + url, data, getAuthConfig(value)),
      (res) => {
        onSuccess(res);
      },
      onFailed
    )
  );
};

export const aDel = (url, onSuccess, onFailed) => {
  getToken().then((value) =>
    wrapRequest(
      Axios.delete(BASE_URL + url, getAuthConfig(value)),
      (res) => {
        onSuccess(res);
      },
      onFailed
    )
  );
};
