import * as yup from 'yup';

export const EditUserSchema = yup.object().shape({

    username: yup.string().required('نام کاربری الزامی می باشد.'),
    password: yup.string().required('کلمه عبور الزامی می باشد.'),
    new_password: yup.string().required('کلمه عبور جدید الزامی می باشد.'),
    new_password_confirm: yup.string().required('تکرار کلمه عبور الزامی می باشد.')
        .oneOf([yup.ref('new_password'), null], 'عبارت وارد شده تطابق ندارد.')


})
export const CreateAdminSchema = yup.object().shape({

    username: yup.string().required('نام کاربری الزامی می باشد.'),
    password: yup.string().required('کلمه عبور الزامی می باشد.'),
    confirm_password: yup.string().required('تکرار کلمه عبور الزامی می باشد.').oneOf([yup.ref('password'), null], 'عبارت وارد شده تطابق ندارد.')


})