import styles from './Form.module.css'
import {Field, useFormikContext} from "formik";
import {useRef} from "react";

/*
 Checkbox with switching effect and Formik Validation
*/
const Switch = ({label,name}) => {

    // Refs
    const checkboxRef = useRef(null);


    // Formik states and helpers via React Context.
    const formikProps = useFormikContext();


    // Set the value of a field imperatively.
    const handleChange = (field, value) => {
        formikProps.setFieldValue(field, checkboxRef.current.checked)
    }

   return(
       <div className={styles.switchContainer}>
           {label ? <p>{label}</p> : null}
           <label className={styles.switch}>
               <Field name={name}>
                   {({field}) => (

                       <input type="checkbox" defaultChecked={field.value}
                              onChange={() => handleChange(field.name)} ref={checkboxRef}/>

                   )}
               </Field>
               <span className={styles.slider}></span>
           </label>
       </div>
   )
}
export default Switch;