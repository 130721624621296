import * as yup from 'yup';

export const UserDetailSchema = yup.object().shape({
    username: yup.string().required('نام کاربری الزامی می باشد.'),
    data_limit: yup.number().required('حجم الزامی می باشد.').typeError('مقادیر ورودی میبایست عدد باشد.'),
    on_hold: yup.boolean(),
    is_active: yup.boolean(),
    hold_days: yup.number().nullable().typeError('مقادیر ورودی میبایست عدد باشد.'),
    expiry_date: yup.string()

})