import styles from './Badge.module.css';

/*
    Badges used as status field in tables or etc.
*/

const Badge = ({icon,text,color,english,key}) => {
    return(
        <div key={key} className={`${styles.badge} ${english ? 'english' : null}`} style={{
            backgroundColor : color ? `var(--${color.toLowerCase()}-bg)` : 'var(--danger-bg)',

        }}>
            <div className={styles.badgeContainer} style={{
                color : color ? `var(--${color.toLowerCase()})` : 'var(--danger)'
            }}>
                {icon ? icon : null}
                <p>{text ? text : 'وضعیت'}</p>
            </div>
        </div>
    );
}
export default Badge;