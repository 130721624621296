import {
  Button,
  Checkbox,
  Form,
  Input,
  Input50,
  Popup,
  Select,
} from "../index";
import { forwardRef, useEffect, useState } from "react";
import { CreateUserSchema } from "../../validation/CreateUserSchema";
import styles from "./User.module.css";
import ClosePopup from "../Popup/ClosePopup";
import { toast } from "react-toastify";
import { getInbounds, postUser } from "../../services/APIs";
import moment from "jalali-moment";

/*
     Create new User
*/

const CreateUser = forwardRef(({ setPopupStatus, panels }, ref) => {
  // Initial field values of the form, Formik will make these values available to render methods component as values.
  const formInitialValues = {
    username: null,
    data_limit: null,
    expiry_date: null,
    countries: null,
    on_hold: false,
    hold_days: null,
  };

  const [inbounds, setInbounds] = useState([]);

  useEffect(() => {
    getInbounds(
      (res) => {
        let allInbounds = [
          ...res.shadowsocks,
          ...res.trojan,
          ...res.vless,
          ...res.vmess,
        ];
        let locInbounds = [];
        for (let i = 0; allInbounds.length; i++) {
          locInbounds = [
            ...locInbounds,
            {
              value: i,
              label: allInbounds[i].tag,
              type: allInbounds[i].protocol,
            },
          ];
          if (i === allInbounds.length - 1) {
            setInbounds(locInbounds);
          }
        }
      },
      (error) => {}
    );
  }, []);

  // Create New User Function
  const createUser = (values) => {
    let locInbounds = {
      vless: [],
      vmess: [],
      trojan: [],
      shadowsocks: [],
    };
    for (let i = 0; i < values.countries.length; i++) {
      locInbounds[values.countries[i].type] = [
        ...locInbounds[values.countries[i].type],
        values.countries[i].label,
      ];
    }
    let payload = values.on_hold
      ? {
          auto_delete_in_days: -1,
          data_limit: parseInt(values.data_limit) * 1024 * 1024 * 1024,
          data_limit_reset_strategy: "no_reset",
          inbounds: locInbounds,
          expire: 0,
          note: "",
          on_hold_expire_duration: values.on_hold
            ? parseInt(values.hold_days) * 86400
            : 0,
          on_hold_timeout: "2025-11-03T20:30:00",
          proxies: {
            vless: {},
            vmess: {
              id: "35e4e39c-7d5c-4f4b-8b71-558e4f37ff53",
            },
          },
          status: values.on_hold ? "on_hold" : "active",
          username: values.username,
        }
      : {
          auto_delete_in_days: -1,
          data_limit: parseInt(values.data_limit) * 1024 * 1024 * 1024,
          data_limit_reset_strategy: "no_reset",
          expire: !values.on_hold
            ? new Date(
                moment(values.expiry_date).format("YYYY-MM-DD")
              ).getTime() / 1000
            : 0,
          inbounds: locInbounds,
          note: "",
          proxies: {
            vless: {},
            vmess: {
              id: "35e4e39c-7d5c-4f4b-8b71-558e4f37ff53",
            },
          },
          status: values.on_hold ? "on_hold" : "active",
          username: values.username,
        };
    postUser(
      payload,
      (res) => {
        ClosePopup(ref, setPopupStatus);
        toast.success("کاربر جدید ساخته شد.");
      },
      (error) => {
        toast.error("مشکلی پیش آمده است.");
      }
    );
    // New User created successfully notification
  };
  return (
    <Popup
      title={"ساخت کاربر جدید"}
      icon={<i className="fa-regular fa-plus"></i>}
      setPopupStatus={setPopupStatus}
      ref={ref}
    >
      <Form
        validationSchema={CreateUserSchema}
        initialValues={formInitialValues}
        onSubmit={(values) => {
          createUser(values);
        }}
      >
        {/*<DateInput/>*/}
        <Input placeHolder={"نام کاربری"} name={"username"} />
        <Input placeHolder={"حجم"} name={"data_limit"} />
        <Input placeHolder={"انقضا"} isDate name={"expiry_date"} />
        <Select
          placeHolder={"کشور ها"}
          options={inbounds}
          isMulti
          name={"countries"}
        />
        <Input50>
          <Checkbox name={"on_hold"} text={"On Hold"} />
          <Input placeHolder={"روز"} name={"hold_days"} />
        </Input50>

        <Button text={"ایجاد"} formButton customClass={styles.footer} submit />
      </Form>
    </Popup>
  );
});
export default CreateUser;
