import styles from './Form.module.css'

/*
    Double Input in a row container
*/
const Input50 = ({children,customClass}) => {
    return (
        <div className={`${styles.input50Container} ${customClass}`}>
            {children}
        </div>
    );
}
export default Input50;