import { Route, Routes } from "react-router-dom";
import { AuthPage, DashboardPage } from "./components";

/*
    Main Component
*/
const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route element={<AuthPage />} index />
        <Route path={"/login"} element={<AuthPage />} index />
        <Route path={"/dashboard/*"} element={<DashboardPage />} />
      </Routes>
    </div>
  );
};

export default App;
